'use client';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Button } from '@/components/atom/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/atom/dropdown-menu';
import { SendPost } from '@/components/icons';
import { Loader2, Trash2 } from 'lucide-react';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import React from 'react';
import { EditorContent } from '@tiptap/react';
import useTipTapEditor from '@/hooks/use-tiptap-editor';
import useLoadPreview from '@/hooks/use-load-preview';
import MetadataPreviewLoader from '@/components/metadata-preview-loader';
import MetadataPreview from '@/components/metadata-preview';
import { CreateCommentPayloadType } from '@/types';
import useCreatePostCommentMutation from '@/hooks/react-query/posts/mutations/useCreatePostCommentMutation';
import { useQueryClient } from '@tanstack/react-query';
import Avatar from '@/components/atom/avatar';
import { cn } from '@/lib/utils';

const PostCommentForm = ({
  placeholder = '',
  className,
  onSubmit,
  id,
  parentType,
}: {
  onSubmit?: (p: Omit<CreateCommentPayloadType, 'contentId' | 'parentType'>) => void;
  placeholder?: string;
  className?: string;
  id: number;
  parentType: number;
}) => {
  const queryClient = useQueryClient();
  const session = useSession();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [values, setValues] = React.useState<{
    comment: string;
    image: File | null;
    imageSrc: string;
  }>({
    comment: '',
    image: null,
    imageSrc: '',
  });

  const { editor, value } = useTipTapEditor({
    placeholder,
    className: '!max-h-[300px] overflow-y-auto bg-pink-brand focus:border-none border-none',
  });
  const { loading, metadata, handleClose } = useLoadPreview(value);
  const createCommentMutation = useCreatePostCommentMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload: CreateCommentPayloadType = {
      comment: value,
      contentId: id,
      allowComments: true,
      allowLikes: true,
      allowShare: true,
      parentType,
    };
    if (values.image) {
      payload['image'] = {
        name: values.image?.name ?? '',
        contentType: values?.image?.type ?? '',
        base64: values?.imageSrc?.split(',')[1] ?? '',
      };
    }
    onSubmit?.(payload);
    createCommentMutation.mutate(payload, {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['GetCommentsByContentId'] });
        queryClient.invalidateQueries({
          queryKey: ['GetAllPosts'],
        });
        queryClient.invalidateQueries({
          queryKey: ['GetCommentsByContentId'],
        });
        queryClient.invalidateQueries({
          queryKey: ['post-detail'],
        });
        editor?.commands.clearContent();
      },
    });
  };

  const handleChange = (e: string | File | null, name: string) => {
    if (name === 'image') {
      const reader = new FileReader();
      reader.onload = (ev) => {
        const imageSrc = ev.target?.result as string;
        setValues((prev) => ({ ...prev, [name]: e as File, imageSrc }));
      };
      reader.readAsDataURL(e as File);
    } else {
      setValues((prev) => ({ ...prev, [name]: e }));
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={cn(`p-2 my-4 rounded-md bg-pink-brand-muted`, className)}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 my-2">
            <Avatar
              fallbackText={`${session?.data?.user?.name?.split(' ')?.[0]?.[0] ?? ''} ${
                session?.data?.user?.name?.split(' ')?.[1]?.[0] ?? ''
              }`}
              className="h-10 w-10"
              src={session?.data?.user?.avatarUrl ?? ''}
              isOnline
            />
            <p className="text-sm">{session?.data?.user?.name}</p>
          </div>
          <div className="flex items-center justify-end gap-3">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="p-0 rounded-full">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                      fill="#F07306"
                    />
                    <path
                      d="M5.93944 8.89232C6.67584 8.89232 7.27281 8.29535 7.27281 7.55895C7.27281 6.82255 6.67584 6.22559 5.93944 6.22559C5.20305 6.22559 4.60608 6.82255 4.60608 7.55895C4.60608 8.29535 5.20305 8.89232 5.93944 8.89232Z"
                      fill="#F3E9FE"
                    />
                    <path
                      d="M12.0778 8.89232C12.8141 8.89232 13.4111 8.29535 13.4111 7.55895C13.4111 6.82255 12.8141 6.22559 12.0778 6.22559C11.3414 6.22559 10.7444 6.82255 10.7444 7.55895C10.7444 8.29535 11.3414 8.89232 12.0778 8.89232Z"
                      fill="#F3E9FE"
                    />
                    <path
                      d="M12.372 11.2118C10.212 12.7318 7.81191 12.7051 5.65186 11.2118C5.38518 11.0251 5.17184 11.3451 5.33185 11.6384C5.99853 12.7051 7.30523 13.6918 9.01194 13.6918C10.7186 13.6918 12.0253 12.7318 12.692 11.6384C12.852 11.3451 12.6387 11.0251 12.372 11.2118Z"
                      fill="#F3E9FE"
                    />
                  </svg>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="p-0 border-none shadow-none" align="start">
                <Picker
                  data={data}
                  onEmojiSelect={(emoji: { native: string }) =>
                    editor?.commands.insertContent(emoji.native)
                  }
                  emojiSize={16}
                  theme="light"
                />
              </DropdownMenuContent>
            </DropdownMenu>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                fileInputRef?.current?.click();
              }}
            >
              <input
                id="image"
                name="image"
                style={{ display: 'none' }}
                type="file"
                ref={fileInputRef}
                onChange={(e) => {
                  handleChange(e.target?.files?.[0] as File, 'image');
                }}
              />
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.475 0.833008H15.025C14.3 0.833008 13.7667 1.13301 13.525 1.66634C13.3917 1.90801 13.3334 2.19134 13.3334 2.52467V4.97467C13.3334 6.03301 13.9667 6.66634 15.025 6.66634H17.475C17.8084 6.66634 18.0917 6.60801 18.3334 6.47467C18.8667 6.23301 19.1667 5.69967 19.1667 4.97467V2.52467C19.1667 1.46634 18.5334 0.833008 17.475 0.833008ZM18.2584 4.10801C18.175 4.19134 18.05 4.24967 17.9167 4.25801H16.7417V4.68301L16.75 5.41634C16.7417 5.55801 16.6917 5.67467 16.5917 5.77467C16.5084 5.85801 16.3834 5.91634 16.25 5.91634C15.975 5.91634 15.75 5.69134 15.75 5.41634V4.24967L14.5834 4.25801C14.3084 4.25801 14.0834 4.02467 14.0834 3.74967C14.0834 3.47467 14.3084 3.24967 14.5834 3.24967L15.3167 3.25801H15.75V2.09134C15.75 1.81634 15.975 1.58301 16.25 1.58301C16.525 1.58301 16.75 1.81634 16.75 2.09134L16.7417 2.68301V3.24967H17.9167C18.1917 3.24967 18.4167 3.47467 18.4167 3.74967C18.4084 3.89134 18.35 4.00801 18.2584 4.10801Z"
                  fill="#601E8A"
                />
                <path
                  d="M7.49993 8.65026C8.5953 8.65026 9.48327 7.76229 9.48327 6.66693C9.48327 5.57156 8.5953 4.68359 7.49993 4.68359C6.40457 4.68359 5.5166 5.57156 5.5166 6.66693C5.5166 7.76229 6.40457 8.65026 7.49993 8.65026Z"
                  fill="#601E8A"
                />
                <path
                  d="M17.475 6.66699H17.0833V10.5087L16.975 10.417C16.325 9.85866 15.275 9.85866 14.625 10.417L11.1583 13.392C10.5083 13.9503 9.45829 13.9503 8.80829 13.392L8.52496 13.1587C7.93329 12.642 6.99163 12.592 6.32496 13.042L3.20829 15.1337C3.02496 14.667 2.91663 14.1253 2.91663 13.492V6.50866C2.91663 4.15866 4.15829 2.91699 6.50829 2.91699H13.3333V2.52533C13.3333 2.19199 13.3916 1.90866 13.525 1.66699H6.50829C3.47496 1.66699 1.66663 3.47533 1.66663 6.50866V13.492C1.66663 14.4003 1.82496 15.192 2.13329 15.8587C2.84996 17.442 4.38329 18.3337 6.50829 18.3337H13.4916C16.525 18.3337 18.3333 16.5253 18.3333 13.492V6.47533C18.0916 6.60866 17.8083 6.66699 17.475 6.66699Z"
                  fill="#601E8A"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex items-end w-full gap-2 my-2">
          <div className="w-full">
            {loading ? (
              <MetadataPreviewLoader />
            ) : (
              metadata && (
                <MetadataPreview
                  className="bg-gray-brand8"
                  handleClose={handleClose}
                  data={metadata}
                />
              )
            )}

            <EditorContent
              editor={editor}
              disabled={createCommentMutation.isPending}
              className="!max-h-[300px] overflow-y-auto bg-pink-brand focus:border-none border-none"
              // className={cn(
              //   'w-full duration-500 ease-in-out overflow-y-auto max-h-[152px] min-h-9 rounded-sm border border-gray-brand2 bg-white px-3 py-1 text-sm transition text-black-brand file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-brand9 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-purple-brand disabled disabled:bg-gray-brand4'
              // )}
            />
          </div>
          <Button
            type="submit"
            color="primary"
            size="sm"
            disabled={value.trim().length === 0 || createCommentMutation.isPending}
            className="gap-2 flex items-center disabled:!bg-gray-brand2 disabled:!text-white"
          >
            {createCommentMutation.isPending ? (
              <Loader2 className="animate-spin" size={18} />
            ) : (
              <>
                <SendPost /> Post
              </>
            )}
          </Button>
        </div>
        {values.image && (
          <div className="relative w-[171px] h-24 overflow-hidden shadow-lg">
            <Image src={values.imageSrc || ''} alt="image" fill className="object-cover" />
            <Button
              variant="ghost"
              size="icon"
              className="absolute bottom-0 right-0 h-fit w-fit p-1"
              onClick={() => {
                setValues((prev) => ({ ...prev, image: null, imageSrc: '' }));
                if (fileInputRef.current) {
                  fileInputRef.current.value = ''; // Reset the file input
                }
              }}
            >
              <Trash2 size={16} className="text-gray-brand9" />
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default PostCommentForm;
