import { CommentIcon, EyeIcon, GiftIcon, HeartIcon, SaveIcon } from '@/components/icons';
import ShareButton from '@/components/share';
import useModalControl from '@/hooks/store/use-modal-control';
import { useMixpanel } from '@/providers/MixPanelProvider';
import { PostType } from '@/types';
import { useSession } from 'next-auth/react';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

type Props = {
  onClick?: (action: string) => void;
  data?: Partial<PostType>;
  hideLike?: boolean;
  hideComment?: boolean;
  hideView?: boolean;
  hideShare?: boolean;
  hideReply?: boolean;
  hideSave?: boolean;
  hideGift?: boolean;
  likeProcessing?: boolean;
};

const PostFooter: React.FC<Props> = ({
  onClick,
  data,
  hideComment,
  hideLike,
  hideReply,
  hideShare,
  hideView,
  hideSave,
  hideGift = false,
  likeProcessing,
}) => {
  const { trackEvent } = useMixpanel();
  const session = useSession();
  const router = useRouter();
  const pathname = usePathname();
  const { openModal } = useModalControl();

  return (
    <div className="flex flex-row items-center justify-between my-2">
      <div className="flex gap-3 md:gap-8">
        {!hideLike && (
          <div className="flex items-center gap-0.5 text-sm md:gap-1">
            {session?.status === 'unauthenticated' ? (
              <button
                className="flex"
                title={!data?.hasJoinedCommunity ? 'join community to like post' : 'like'}
                onClick={(e) => {
                  e.stopPropagation();
                  router.push(`${pathname}?ref=signin`);
                  trackEvent('button_clicked', {
                    title: 'Anonymous user tried to like a post and sees a modal',
                  });
                }}
              >
                <HeartIcon />
              </button>
            ) : (session?.status === 'authenticated' && data?.communityTypeId === 1) ||
              (session?.status === 'authenticated' &&
                data?.communityTypeId === 2 &&
                data?.hasJoinedCommunity) ? (
              //  allow like for public community posts
              //  allow like for private community posts if user has joined community
              <button
                className="flex"
                title={!data?.hasJoinedCommunity ? 'join community to like post' : 'like'}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick?.('like');
                }}
              >
                <HeartIcon filled={data?.isLiked} />
              </button>
            ) : session?.status === 'authenticated' &&
              data?.communityTypeId === 2 &&
              !data?.hasJoinedCommunity ? (
              // if post is prvate community post and user has not joined, tell user to join community first
              <button
                className="flex"
                title={!data?.hasJoinedCommunity ? 'join community to like post' : 'like'}
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('joinPrivateCommunity', {
                    communityName: data?.communityName,
                    communitySlug: data?.communitySlug,
                  });
                  trackEvent('button_clicked', {
                    title:
                      'Authenticated user tried to like a post from a prvate community and sees a modal',
                  });
                }}
              >
                <HeartIcon />
              </button>
            ) : (
              <button
                disabled={!data?.hasJoinedCommunity || likeProcessing}
                className={!data?.hasJoinedCommunity ? 'cursor-not-allowed flex' : ' flex'}
                title={!data?.hasJoinedCommunity ? 'join community to like post' : ''}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick?.('like');
                }}
              >
                <HeartIcon filled={data?.isLiked} />
              </button>
            )}
            <span>{data?.likeCount ?? 0}</span>
          </div>
        )}
        {!hideComment && (
          <div className="flex items-center gap-0.5 text-sm md:gap-1">
            {session?.status === 'unauthenticated' ? (
              <button
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  router.push(`${pathname}?ref=signin`);
                  trackEvent('button_clicked', {
                    title: 'Anonymous user tried to comment on a post and sees a modal',
                  });
                }}
              >
                <CommentIcon />
              </button>
            ) : (session?.status === 'authenticated' && data?.communityTypeId === 1) ||
              (session?.status === 'authenticated' &&
                data?.communityTypeId === 2 &&
                data?.hasJoinedCommunity) ? (
              //  allow comment for public community posts
              //  allow comment for private community posts if user has joined community
              <button
                className="flex"
                title={!data?.hasJoinedCommunity ? 'join community to comment on post' : 'like'}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick?.('comment');
                }}
              >
                <CommentIcon />
              </button>
            ) : session?.status === 'authenticated' &&
              data?.communityTypeId === 2 &&
              !data?.hasJoinedCommunity ? (
              // if post is prvate community post and user has not joined, tell user to join community first
              <button
                className="flex"
                title={!data?.hasJoinedCommunity ? 'join community to like post' : 'like'}
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('joinPrivateCommunity', {
                    communityName: data?.communityName,
                    communitySlug: data?.communitySlug,
                  });
                  trackEvent('button_clicked', {
                    title:
                      'Authenticated user tried to comment on a post from a prvate community and sees a modal',
                  });
                }}
              >
                <CommentIcon />
              </button>
            ) : (
              <button onClick={() => onClick?.('comment')}>
                <CommentIcon />
              </button>
            )}

            <span>{data?.commentCount ?? 0}</span>
          </div>
        )}
        {!hideView && (
          <div className="flex items-center gap-0.5 text-sm md:gap-1">
            {session?.status === 'unauthenticated' ? (
              <button
                className="flex cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  router.push(`${pathname}?ref=signin`);
                }}
              >
                <EyeIcon />
              </button>
            ) : (
              <button className="flex cursor-default" onClick={() => onClick?.('view')}>
                <EyeIcon />
              </button>
            )}

            <span>{data?.viewCount ?? 0}</span>
          </div>
        )}

        {!hideShare && (
          <ShareButton
            // disabled={!data?.allowShare}
            path={`posts/${data?.slug}`}
            count={data?.shareCount ?? 0}
          />
        )}

        {!hideReply && (
          <div className="flex items-center gap-0.5 text-sm md:gap-1">
            {session?.status === 'unauthenticated' ? (
              <button
                className="flex cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  router.push(`${pathname}?ref=signin`);
                }}
              >
                Reply
              </button>
            ) : (
              <button className="flex cursor-default" onClick={() => onClick?.('reply')}>
                Reply
              </button>
            )}
          </div>
        )}
      </div>
      <div className="flex gap-2 md:gap-8">
        {!hideSave && (
          <div className="flex gap-0.5 text-sm md:gap-1">
            <button disabled className="flex cursor-default" onClick={() => {}}>
              {/* TODO: should change selected when save is implemented */}
              <SaveIcon selected={false} />
            </button>
            <span>{data?.reportCount}</span>
          </div>
        )}
        {!hideGift && (
          <div className="flex gap-0.5 text-sm md:gap-1">
            <button disabled className="flex cursor-default" onClick={() => {}}>
              <GiftIcon />
            </button>
            {/* <span>{data?.updateCount}</span> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default PostFooter;
